<template>
  <div class="cart-page">
    <div class="cart-card">
      <div class="cart-message">
        <div class="cart-message-state">
          <div><i class="el-icon-success"></i> {{ goodsTipsMsg }}</div>
          <el-button class="car-buy-btn" @click="gobacktoshopping"
            >Continue shopping</el-button
          >
        </div>
      </div>
      <div class="cart-table">
        <el-table :data="carList" stripe style="width: 100%">
          <el-table-column prop="pic" label="" style="width: 20%">
            <template slot-scope="scope">
              <div class="products-pic">
                <div class="circle-delete-box" @click="delCart(scope.row)">
                  ×
                </div>
                <img :src="scope.row.goods?.image" alt="" width="100%" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Products" label="Products" style="width: 20%">
            <template slot-scope="scope">
              <div class="products-des" @click="toGoodDetail(scope.row.goods)">
                {{ scope.row.goods?.title }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Price" label="Price" style="width: 20%">
            <template slot-scope="scope">
              <div class="products-Price">
                ${{
                  isVip ? scope.row.goods?.price : scope.row.goods?.marketprice
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Quantity" label="Quantity" style="width: 20%">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.nums"
                @change="changeNums(scope.row)"
                :min="1">
              </el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="Subtotal" label="Subtotal" style="width: 20%">
            <template slot-scope="scope">
              <div class="products-Price">
                ${{
                  isVip
                    ? (scope.row.goods?.price * scope.row.nums).toFixed(1)
                    : (scope.row.goods?.marketprice * scope.row.nums).toFixed(1)
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="car-buy-btn-box">
          <el-button class="car-buy-btn" @click="updatedCar()"
            >updated Car</el-button
          >
        </div> -->
      </div>
      <div class="cart-totle-box">
        <div class="cart-totle-title">Cart totals</div>
        <div class="totle-title">
          <!-- <div>
            <span>Subtotal</span>
            <span class="totle-price">${{ subtotal }}</span>
          </div> -->
          <div>
            <span>Total</span>
            <span class="totle-price">${{ totalPrice ? totalPrice : 0 }}</span>
          </div>
        </div>
        <div class="cart-totle-btn-box">
          <el-button class="car-buy-btn" @click="toCheckout()"
            >Proceed to checkout</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { delCarApi, changeCarNumApi } from "@/network/api.js";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "cart",
  data() {
    return {
      goodsTipsMsg: "Cart updated.",
    };
  },
  computed: {
    ...mapGetters(["isVip"]),
    ...mapState(["carList"]),
    // subtotal() {
    //   if (this.carList) {
    //     let subt = 0;
    //     for (const item of this.carList) {
    //       if (this.isVip) {
    //         subt += item.goods?.price * 1;
    //       } else {
    //          subt += item.goods?.marketprice * 1;
    //       }

    //     }
    //     return subt.toFixed(1);
    //   }
    // },
    totalPrice() {
      if (this.carList) {
        let subt = 0;
        for (const item of this.carList) {
          if (this.isVip) {
            subt += item.goods?.price * item.nums;
          } else {
            subt += item?.goods?.marketprice * item?.nums;
          }
        }
        return subt.toFixed(1);
      }
    },
  },
  created() {
    this.getCartList();
    // console.log('购物车',this.carList);
  },
  methods: {
    ...mapActions(["getCartList"]),
    ...mapMutations(["setShowTipPopup"]),
    gobacktoshopping() {
      // this.$router.push('/Mall');
      this.$router.go(-1);
    },
    async changeNums(item) {
      console.log(item);
      const params = {
        id: item.id,
        nums: item.nums,
        type: "nums",
      };
      try {
        const res = await changeCarNumApi(params);
      } catch (error) {
        this.setShowTipPopup(true);
      }
      try {
        await this.getCartList();
      } catch (error) {}
      this.loading = false;
    },
    toGoodDetail(scope) {
      this.$router.push({
        path: `products/${item.id}`,
        // path: "/MallDetail",
        // query: {
        //   id: scope.id,
        // },
      });
    },
    toCheckout() {
      this.$router.push("/Checkout");
    },
    updatedCar() {
      this.getCartList();
    },
    async delCart(item) {
      const params = {
        ids: item.id,
      };
      const res = await delCarApi(params);
      this.getCartList();
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-page {
  width: 100%;
  height: 9.8rem;
  overflow: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding: 20px 0;
  background: #fff;

  .cart-card {
    position: relative;
    width: 60%;
    margin: 0 auto;
    background-color: #fff;

    .cart-message {
      width: 100%;
      border-top: 3px solid #ffb100;
      padding: 1em 3em 1em 3em !important;
      margin-bottom: 35px;
      background: #f8f8f8;
      color: #797979;

      .cart-message-state {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .car-buy-btn {
          background-color: #ffb100;
          color: #fff;
          font-weight: bold;
        }
        .car-buy-btn:hover {
          background-color: #0e1422;
        }
      }
    }

    .cart-table {
      width: 109%;
      .products-pic {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .circle-delete-box {
          width: 25px;
          height: 25px;
          background-color: #0e1422;
          border: 1px solid #0e1422;
          color: #fff;
          border-radius: 50%;
          text-align: center;
          line-height: 25px;
          font-size: 12px;
          cursor: pointer;
        }
        .circle-delete-box:hover {
          cursor: pointer;
          background-color: #d63637;
          border: 1px solid #d63637;
        }

        img {
          width: 65px;
          height: 80px;
          margin: 0 0.2rem;
          flex-shrink: 0;
        }
      }
      .products-des {
        color: #000;
      }
      .products-des:hover {
        color: #ffb100;
      }
      .products-Price {
        color: #ffb100;
        font-weight: bold;
      }
    }

    .car-buy-btn-box {
      text-align: right;
      border: 1px solid #ddd;
      border-top: 0;
      padding: 10px;
      .car-buy-btn {
        background-color: #ffb100;
        color: #fff;
        font-weight: bold;
      }
      .car-buy-btn:hover {
        background-color: #0e1422;
      }
    }

    .cart-totle-box {
      width: 50%;
      position: absolute;
      right: -9%;
      margin-top: 20px;
      text-align: left;
      .cart-totle-title {
        font-weight: bold;
        font-size: 26px;
        color: #0e1422;
        margin-bottom: 10px;
      }
      .totle-title {
        div {
          display: flex;
          font-size: 16px;
          font-weight: font;
          border: 1px solid #ddd;
          border-collapse: collapse;
        }
        // div:first-child {
        //   border-bottom: 0;
        // }

        span {
          display: inline-block;
          width: 50%;
          padding: 16px;
          color: #0e1422;
          text-align: left;
        }
        .totle-price {
          background-color: #f2f2f2;
        }
      }
      .cart-totle-btn-box {
        padding: 10px 0;
        .car-buy-btn {
          background-color: #ffb100;
          color: #fff;
          font-weight: bold;
          padding: 14px 24px;
        }
        .car-buy-btn:hover {
          background-color: #0e1422;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-icon-success {
  color: #ffb100;
}
.el-table {
  border: 1px solid #ddd;
  border-bottom: 0;
  border-collapse: collapse;
  font-size: 18px;
}
.el-table th.el-table__cell > .cell {
  text-align: center;
  color: #0e1422;
}
.el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: center;
}
.el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: none;
}
.el-input-number .el-input__inner:hover {
  color: #ffb100;
  border-color: #ffb100;
}
</style>
